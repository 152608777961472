import React, { FC } from "react"

interface CarProps {
  className?: string;
}

export const Car: FC<CarProps> = ({ className }) => (
  <svg
    className={className}
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3060_25877)">
      <path
        d="M7.25513 29.9054H13.8025V33.0906C13.8025 33.513 13.677 33.918 13.4538 34.2167C13.2305 34.5154 12.9277 34.6832 12.612 34.6832H8.44555C8.12983 34.6832 7.82704 34.5154 7.60379 34.2167C7.38055 33.918 7.25513 33.513 7.25513 33.0906V29.9054Z"
        fill="#0099CC"
      />
      <path
        d="M29.1975 29.9054H35.9218V33.0906C35.9218 33.513 35.793 33.918 35.5637 34.2167C35.3344 34.5154 35.0234 34.6832 34.6992 34.6832H30.4201C30.0959 34.6832 29.7849 34.5154 29.5556 34.2167C29.3263 33.918 29.1975 33.513 29.1975 33.0906V29.9054Z"
        fill="#0099CC"
      />
      <path
        d="M3198.67 1761.44H-2248.76V-1931.14H3198.67V1761.44ZM-2248.19 1760.84H3198.13V-1930.59H-2248.19V1760.84Z"
        fill="#333333"
      />
      <path
        d="M30.6131 10.9718C30.0469 9.27306 28.0768 9.0843 27.1625 9.20227C24.0953 9.23154 17.4831 9.27238 15.5719 9.2016C13.6608 9.13082 12.8291 9.93891 12.6522 10.3518L10.7942 15.2181C10.6467 15.631 10.1925 16.8107 9.55548 18.2263C7.07812 19.6419 6.28185 20.8806 6.19339 21.323V25.9238C5.30866 26.1008 5.13166 26.5432 5.04318 28.6666C4.9724 30.3654 5.78049 30.908 6.19339 30.967H35.5679C37.6913 30.967 37.9273 30.3772 37.7798 30.0823C37.8093 29.0795 37.8506 27.0032 37.7798 26.7201C37.709 26.437 37.0425 25.9533 36.7181 25.7469V21.7654C36.7181 21.323 36.6296 21.146 36.3642 20.5267C36.1518 20.0312 34.1522 18.6097 33.179 17.9609C32.5596 16.339 31.1794 12.6706 30.6131 10.9718Z"
        fill="white"
      />
      <path
        d="M36.7297 31.0558H6.27138C5.87539 31.0558 5.49563 30.8985 5.21563 30.6185C4.93562 30.3385 4.77832 29.9587 4.77832 29.5627V27.1738C4.77832 26.7779 4.93562 26.3981 5.21563 26.1181C5.49563 25.8381 5.87539 25.6808 6.27138 25.6808H36.7297C37.1257 25.6808 37.5055 25.8381 37.7855 26.1181C38.0655 26.3981 38.2228 26.7779 38.2228 27.1738V29.5627C38.2228 29.9587 38.0655 30.3385 37.7855 30.6185C37.5055 30.8985 37.1257 31.0558 36.7297 31.0558ZM6.27138 26.278C6.03379 26.278 5.80593 26.3724 5.63793 26.5404C5.46992 26.7084 5.37554 26.9363 5.37554 27.1738V29.5627C5.37554 29.8003 5.46992 30.0282 5.63793 30.1962C5.80593 30.3642 6.03379 30.4586 6.27138 30.4586H36.7297C36.9673 30.4586 37.1952 30.3642 37.3632 30.1962C37.5312 30.0282 37.6255 29.8003 37.6255 29.5627V27.1738C37.6255 26.9363 37.5312 26.7084 37.3632 26.5404C37.1952 26.3724 36.9673 26.278 36.7297 26.278H6.27138Z"
        fill="#333333"
      />
      <path
        d="M28.1406 9.55535C28.6322 9.556 29.1117 9.70834 29.5136 9.99159C29.9155 10.2748 30.2202 10.6752 30.3861 11.138L32.8825 18.1195L32.9482 18.3047L33.1154 18.4122L35.5043 20.0187C35.7882 20.2028 36.0216 20.4549 36.1833 20.7522C36.3449 21.0495 36.4297 21.3824 36.43 21.7208V25.6804H6.5689V21.7208C6.56787 21.3835 6.65086 21.0513 6.81039 20.7541C6.96993 20.4569 7.20097 20.2042 7.48265 20.0187L9.87154 18.4122L10.0388 18.3047L10.1045 18.1195L12.6008 11.138C12.7675 10.6733 13.074 10.2716 13.4782 9.98814C13.8825 9.70473 14.3646 9.55354 14.8583 9.55535H28.1406ZM28.1406 8.95813H14.8583C14.2422 8.95804 13.6411 9.14855 13.1375 9.50354C12.6339 9.85852 12.2525 10.3606 12.0454 10.9409L9.55501 17.9165L7.16612 19.523C6.80083 19.762 6.50051 20.0879 6.29205 20.4714C6.0836 20.855 5.97352 21.2842 5.97168 21.7208V26.2776H37.0272V21.7208C37.0254 21.2842 36.9153 20.855 36.7069 20.4714C36.4984 20.0879 36.1981 19.762 35.8328 19.523L33.4439 17.9165L30.9535 10.9409C30.7465 10.3606 30.365 9.85852 29.8614 9.50354C29.3578 9.14855 28.7567 8.95804 28.1406 8.95813Z"
        fill="#333333"
      />
      <path
        d="M35.1938 15.8269C35.1324 15.8268 35.0725 15.8079 35.0223 15.7726C34.9721 15.7374 34.9339 15.6875 34.9131 15.6298L32.351 8.46312C32.3266 8.3893 32.332 8.30889 32.366 8.23898C32.4 8.16907 32.4599 8.11517 32.533 8.08873C32.6061 8.06229 32.6866 8.06539 32.7575 8.09739C32.8283 8.12938 32.8839 8.18774 32.9124 8.26006L35.4745 15.4267C35.5013 15.5012 35.4976 15.5833 35.464 15.6549C35.4304 15.7266 35.3697 15.782 35.2953 15.809L35.1938 15.8269Z"
        fill="#333333"
      />
      <path
        d="M35.7017 12.2435C35.6408 12.2419 35.5816 12.2223 35.5317 12.1873C35.4818 12.1522 35.4433 12.1032 35.421 12.0465L34.143 8.46312C34.1186 8.3893 34.124 8.30889 34.158 8.23898C34.192 8.16907 34.2519 8.11517 34.325 8.08873C34.3981 8.06229 34.4786 8.06539 34.5494 8.09739C34.6203 8.12938 34.6759 8.18774 34.7044 8.26006L35.9824 11.8434C35.9966 11.8802 36.0032 11.9195 36.0018 11.9589C36.0004 11.9983 35.991 12.037 35.9743 12.0727C35.9575 12.1084 35.9338 12.1403 35.9044 12.1666C35.875 12.1929 35.8406 12.213 35.8033 12.2256C35.7708 12.2378 35.7364 12.2439 35.7017 12.2435V12.2435Z"
        fill="#333333"
      />
      <path
        d="M25.9377 20.6959C23.0153 20.0449 19.9854 20.0449 17.063 20.6959C16.7924 20.7554 16.551 20.9071 16.3799 21.125C16.2089 21.3429 16.1189 21.6134 16.1253 21.8904V25.6827H26.8753V21.8605C26.8749 21.5887 26.7817 21.3251 26.6113 21.1134C26.4408 20.9016 26.2032 20.7544 25.9377 20.6959Z"
        fill="#0099CC"
      />
      <path
        d="M10.4516 20.903C10.7469 20.903 11.0356 20.9906 11.2811 21.1546C11.5266 21.3187 11.718 21.5519 11.831 21.8247C11.944 22.0975 11.9736 22.3977 11.916 22.6873C11.8584 22.977 11.7162 23.243 11.5074 23.4518C11.2985 23.6606 11.0325 23.8028 10.7429 23.8604C10.4533 23.918 10.1531 23.8885 9.88024 23.7755C9.60742 23.6625 9.37424 23.4711 9.21018 23.2256C9.04612 22.98 8.95855 22.6914 8.95855 22.3961C8.95855 22.0001 9.11586 21.6203 9.39586 21.3403C9.67586 21.0603 10.0556 20.903 10.4516 20.903ZM10.4516 20.3058C10.0382 20.3058 9.63405 20.4284 9.29031 20.6581C8.94657 20.8877 8.67865 21.2142 8.52044 21.5961C8.36223 21.9781 8.32084 22.3984 8.40149 22.8039C8.48215 23.2093 8.68123 23.5818 8.97356 23.8741C9.26589 24.1664 9.63834 24.3655 10.0438 24.4462C10.4493 24.5268 10.8696 24.4854 11.2515 24.3272C11.6335 24.169 11.9599 23.9011 12.1896 23.5574C12.4193 23.2136 12.5419 22.8095 12.5419 22.3961C12.5419 21.8417 12.3217 21.31 11.9297 20.918C11.5377 20.526 11.006 20.3058 10.4516 20.3058V20.3058Z"
        fill="#333333"
      />
      <path
        d="M32.5483 20.903C32.8436 20.903 33.1323 20.9906 33.3778 21.1546C33.6233 21.3187 33.8147 21.5519 33.9277 21.8247C34.0407 22.0975 34.0703 22.3977 34.0127 22.6873C33.955 22.977 33.8128 23.243 33.604 23.4518C33.3952 23.6606 33.1292 23.8028 32.8396 23.8604C32.5499 23.918 32.2497 23.8885 31.9769 23.7755C31.7041 23.6625 31.4709 23.4711 31.3069 23.2256C31.1428 22.98 31.0552 22.6914 31.0552 22.3961C31.0552 22.0001 31.2125 21.6203 31.4925 21.3403C31.7725 21.0603 32.1523 20.903 32.5483 20.903ZM32.5483 20.3058C32.1349 20.3058 31.7307 20.4284 31.387 20.6581C31.0432 20.8877 30.7753 21.2142 30.6171 21.5961C30.4589 21.9781 30.4175 22.3984 30.4982 22.8039C30.5788 23.2093 30.7779 23.5818 31.0702 23.8741C31.3626 24.1664 31.735 24.3655 32.1405 24.4462C32.546 24.5268 32.9663 24.4854 33.3482 24.3272C33.7301 24.169 34.0566 23.9011 34.2863 23.5574C34.516 23.2136 34.6386 22.8095 34.6386 22.3961C34.6386 21.8417 34.4183 21.31 34.0263 20.918C33.6343 20.526 33.1027 20.3058 32.5483 20.3058V20.3058Z"
        fill="#333333"
      />
      <path
        d="M27.6097 11.9445C27.7324 11.9446 27.8521 11.9824 27.9526 12.053C28.053 12.1235 28.1293 12.2233 28.1711 12.3387L29.9627 17.3195H13.0494L14.8411 12.3387C14.8828 12.2233 14.9591 12.1235 15.0595 12.053C15.16 11.9824 15.2797 11.9446 15.4024 11.9445H27.6097ZM27.6097 11.3473H15.3905C15.1443 11.3478 14.9043 11.4244 14.7033 11.5666C14.5023 11.7088 14.3502 11.9096 14.2677 12.1416L12.4761 17.1165C12.4431 17.2076 12.4328 17.3054 12.446 17.4014C12.4593 17.4973 12.4957 17.5887 12.5522 17.6674C12.6086 17.7462 12.6834 17.8101 12.77 17.8535C12.8566 17.8969 12.9525 17.9186 13.0494 17.9167H29.9508C30.0467 17.9167 30.1411 17.8936 30.2262 17.8493C30.3112 17.8051 30.3844 17.741 30.4394 17.6625C30.4945 17.584 30.5299 17.4934 30.5425 17.3984C30.5552 17.3033 30.5448 17.2066 30.5122 17.1165L28.7205 12.1416C28.6387 11.9115 28.4884 11.7121 28.2898 11.57C28.0912 11.428 27.8538 11.3503 27.6097 11.3473Z"
        fill="#333333"
      />
    </g>
    <defs>
      <clipPath id="clip0_3060_25877">
        <rect width="43" height="43" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
