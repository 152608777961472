/* eslint-disable prefer-destructuring */
import * as React from "react"
import { MouseEvent } from "react"

export const messageErrors = {
  system_default: {
    title:
      "Disculpa los inconvenientes, vuelve a intentar tu cotización por favor",
    key: "default",
    errorLabel: "Error General",
    description: null,
    content: (
      <p className="mx-auto w-[264px] sm:w-[310px] leading-none">
        Estamos trabajando para poder <br /> solucionar los inconvenientes.{" "}
        <br /> Inténtalo nuevamente dentro de unos minutos.
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: <button className="py-2">Regresar a Inicio</button>,
        action: null
      }
    }
  },
  timeout: {
    title: null,
    key: "timeout",
    errorLabel: "Sesión Expirada",
    description: "Tiempo de espera agotado.",
    content: (
      <p className="mx-auto w-[264px] sm:w-[310px]">
        Por favor, vuelve a intentarlo
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: <span className="block py-2.5">Aceptar</span>,
        action: null
      },
      secondaryButton: null
    }
  },
  pay_default: {
    title: null,
    key: "pay_default",
    errorLabel: "Medio de pago – Datos Incorrectos",
    description: "Ocurrió un problema al realizar el pago de tu SOAT Digital.",
    content: (
      <p className="mx-auto w-[264px] sm:w-[315px]">
        <b>Por favor,</b> verifica que los datos del medio de pago sean los
        correctos.
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: (
          <a className="block py-2.5" href="tel:015135000">
            Llamar al (01) 513-5000 opc 3
          </a>
        ),
        action: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => { }
      },
      secondaryButton: {
        buttonText: "Regresar a Inicio",
        action: null
      }
    }
  },
  pay_insufficient: {
    title: null,
    key: "pay_insufficient",
    errorLabel: "Medio de pago – Saldo insuficiente",
    description: "Ocurrió un problema al realizar el pago de tu SOAT Digital.",
    content: (
      <p className="mx-auto w-[264px] sm:w-[310px]">
        <b>Por favor,</b> verifica que tu medio de pago cuente con saldo
        suficiente.
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: null,
        action: null
      },
      secondaryButton: null
    }
  },
  conection: {
    title: null,
    key: "conection",
    errorLabel: "Error de conexión",
    description: "Actualmente, se están presentando problemas de conexión.",
    content: (
      <p className="mx-auto w-[264px] sm:w-[310px]">
        Por favor, vuelve a intentarlo o llámanos al <b>(01) 513-5000.</b>
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: null,
        action: null
      },
      secondaryButton: null
    }
  },
  maintenance: {
    title: null,
    key: "maintenance",
    errorLabel: "Error por mantenimiento",
    description: "Actualmente nos encontramos en mantenimiento.",
    content: (
      <p className="mx-auto w-[264px] sm:w-[310px]">
        Por favor, llámanos al <b>(01) 513-5000</b> o vuelve a intentarlo más
        tarde.
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: null,
        action: null
      },
      secondaryButton: null
    }
  },
  blacklist: {
    title: "SOAT no disponible",
    key: "blacklist",
    errorLabel: "Cliente en blacklist",
    description: null,
    content: (
      <p className="mx-auto w-[274px] sm:w-[315px]">
        Actualmente no podemos ofrecerte un SOAT
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: <span className="block py-2.5">Entendido</span>,
        action: null
      },
      secondaryButton: null
    }
  },
  not_available: {
    title: "Lo sentimos, en este momento no contamos con un plan adecuado para tu vehículo",
    key: "not_available",
    errorLabel: "",
    description: null,
    content: (
      <p className="px-6 text-base/5 md:text-2xl/[1.875rem] md:px-8">
        Estamos trabajando para mejorar nuestras ofertas
        <br className="hidden sm:inline" />de SOAT y brindarte la mejor protección.
        <br className="hidden sm:inline" />Gracias por tu comprensión
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: <button className="py-2">Cerrar</button>,
        action: null
      }
    }
  },
  emission: {
    title: null,
    key: "emission",
    errorLabel: "Error de emisión",
    description: "Hubo un problema con la emisión de tu certificado.",
    content: (
      <div>
        <div className="rounded-xl bg-mist mx-auto py-3 mb-5 sm:mb-3 w-[307px] sm:w-[353px]">
          <p className="text-base sm:text-lg font-semibold text-info">
            Número de cotización y pedido
          </p>
          <p className="mb-1.5 text-sm sm:text-lg">192034981</p>
          <p className="text-base sm:text-lg font-semibold text-info">
            Número de operación
          </p>
          <p className="mb-1.5 text-sm sm:text-lg">192034981</p>
          <p className="text-base sm:text-lg font-semibold text-info">
            Fecha y hora de la transacción
          </p>
          <p className="text-sm sm:text-lg">07/11/2022 - 11:11 </p>
        </div>
        <p className="mx-auto leading-6 w-[267px] sm:w-[315px]">
          Por favor, llámanos al <b>(01) 513-5000</b> para recibir una atención
          personalizada.
        </p>
      </div>
    ),
    buttons: {
      mainButton: {
        buttonText: null,
        action: null
      },
      secondaryButton: null
    }
  },
  generic_default: {
    title: "Disculpa las molestias",
    key: "generic",
    errorLabel: "Error de emisión",
    description: null,
    content: (
      <p className="mx-auto w-[264px] sm:w-[310px] leading-none">
        Estamos presentando inconvenientes <br /> para cotizar tu SOAT. <br />{" "}
        <br /> Por favor, comunícate con un asesor.
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: (
          <a className="block py-2.5" href="tel:015135000">
            Llamar al (01) 513-5000 opc 3
          </a>
        ),
        action: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => { }
      },
      secondaryButton: {
        buttonText: "Regresar a Inicio",
        action: null
      }
    }
  },
  dataSendError: {
    title: "",
    key: "dataSendError",
    errorLabel: "Error de datos",
    description: null,
    content: (
      <p className="mx-auto w-[264px] sm:w-[310px]">
        Te sugerimos revisar los datos ingresados.
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: (
          <a className="block py-2.5" href="tel:015135000">
            Llamar al (01) 513-5000 opc 3
          </a>
        ),
        action: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => { }
      },
      secondaryButton: {
        buttonText: "Regresar a Inicio",
        action: null
      }
    }
  },
  certificate: {
    title: (
      <>
        Estamos trabajando <br /> en tu Certificado
      </>
    ),
    key: "certificate",
    errorLabel: "Error de generar certificado",
    description: null,
    content: <span></span>,
    buttons: {
      mainButton: {
        buttonText: <button className="py-2">Volver a intentarlo</button>,
        action: null
      }
    }
  },
  errorPago: {
    title: <></>,
    key: "error-pago",
    errorLabel: "Error al procesar el pago",
    description: null,
    buttons: {
      mainButton: {
        buttonText: <button className="py-2">Volver a intentarlo</button>,
        action: null
      },
      secondaryButton: {
        buttonText: "Regresar a Inicio"
      }
    }
  },
  errorRenovation: {
    title: null,
    key: "error-renovation",
    errorLabel: "Error al renovar el vehiculo",
    description: null,
    content: (
      <p className="mx-auto w-[264px] sm:w-[315px]">
        Por favor, para ayudarte a continuar con tu solicitud, podemos
        contactarte con un asesor <br />
        <b>(01) 513-5000 op.3</b>
      </p>
    ),
    buttons: {
      mainButton: {
        buttonText: (
          <a
            className="flex py-2.5 justify-center items-center gap-5"
            href="tel:015135000"
          >
            <img src="/call.svg" alt="Icono de telefono" /> Llamar ahora
          </a>
        ),
        action: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => { }
      },
      secondaryButton: null
    }
  }
}
