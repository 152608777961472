import React from "react"

export const Motorcycle = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 512.001 512.001"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill="#CEE8FA"
        d="M100.74,442.84c-47.725,0-86.552-38.826-86.552-86.552s38.826-86.552,86.552-86.552
		s86.552,38.826,86.552,86.552S148.466,442.84,100.74,442.84z"
      />
      <path
        fill="#CEE8FA"
        d="M411.261,442.84c-47.725,0-86.552-38.826-86.552-86.552s38.826-86.552,86.552-86.552
		s86.552,38.826,86.552,86.552S458.985,442.84,411.261,442.84z"
      />
    </g>
    <g>
      <path
        fill="#2D527C"
        d="M147.683,148.148H37.944c-7.836,0-14.189-6.352-14.189-14.189c0-7.836,6.352-14.189,14.189-14.189
		h109.74c7.836,0,14.189,6.352,14.189,14.189C161.873,141.796,155.519,148.148,147.683,148.148z"
      />
      <path
        fill="#2D527C"
        d="M411.26,255.547c-1.023,0-2.036,0.047-3.052,0.078l-2.473-14.267h84.391
		c7.835,0,14.189-6.352,14.189-14.189s-6.354-14.189-14.189-14.189h-16.077v-44.795c0-25.736-20.938-46.674-46.674-46.674H401.39
		c-5.475,0-10.874,0.613-16.121,1.803L375.46,66.74c-1.178-6.799-7.079-11.765-13.98-11.765h-68.106
		c-7.835,0-14.189,6.352-14.189,14.189s6.354,14.189,14.189,14.189h56.166l9.033,52.097l-66.875,46.461
		c-12.532,8.709-57.405,10.917-72.746,3.58l-0.007,0.014c-2.013-0.925-5.146-2.111-10.547-4.154
		c-5.63-2.13-13.112-4.936-20.601-7.727c-7.487-2.791-14.981-5.568-20.632-7.642c-11.158-4.095-12.682-4.655-15.541-4.282H37.944
		c-7.836,0-14.189,6.352-14.189,14.189c0,7.836,6.352,14.189,14.189,14.189h112.463c9.687,3.456,51.249,18.959,56.345,21.036
		c22.718,10.826,78.816,9.61,101.138-5.898l56.03-38.927l16.326,94.164c-40.415,13.112-69.725,51.105-69.725,95.834
		c0,55.548,45.193,100.741,100.74,100.741s100.74-45.193,100.74-100.741S466.809,255.547,411.26,255.547z M152.815,190.072
		c0.627-0.004,1.247-0.043,1.856-0.128C154.049,190.033,153.431,190.066,152.815,190.072z M157.788,189.147
		c3.266-1.247,6.005-3.679,7.612-6.874C163.7,185.695,160.89,187.973,157.788,189.147z M199.075,194.808
		c-0.854,3.356-0.427,6.813,1.037,9.778C198.693,201.745,198.161,198.338,199.075,194.808z M202.776,208.309
		c1.054,1.058,2.292,1.965,3.692,2.666C205.13,210.331,203.875,209.421,202.776,208.309z M401.39,149.889h25.984
		c10.088,0,18.296,8.208,18.296,18.296v44.795h-44.856l-10.683-61.613C393.779,150.408,397.55,149.889,401.39,149.889z
		 M411.26,428.651c-39.9,0-72.363-32.461-72.363-72.363c0-30.7,19.227-56.979,46.265-67.473l12.119,69.898
		c1.196,6.9,7.189,11.767,13.963,11.767c0.804,0,1.62-0.068,2.44-0.21c7.722-1.338,12.896-8.684,11.557-16.404l-12.117-69.893
		c39.04,0.995,70.501,33.039,70.501,72.316C483.622,396.188,451.16,428.651,411.26,428.651z"
      />
      <path
        fill="#2D527C"
        d="M347.132,260.965c0.8-0.541,1.61-1.073,2.426-1.595c6.602-4.22,8.536-12.993,4.316-19.596
		c-4.22-6.602-12.99-8.539-19.598-4.316c-1.019,0.651-2.028,1.315-3.028,1.991c-38.105,25.734-60.915,69.142-61.012,116.114
		l-0.018,8.855h-28.501v-8.483c0-48.861-25.372-94.783-66.216-119.844c-15.731-9.654-32.912-16.087-51.067-19.124
		c-0.001,0-0.001,0-0.001,0c-7.886-1.318-15.856-1.986-23.692-1.986c-7.605,0-13.858,5.996-14.176,13.596
		c-0.318,7.6,5.41,14.097,12.99,14.732l20.64,1.732c12.161,2.081,23.772,6.084,34.616,11.902l-8.234,11.682
		c-13.76-7.063-29.334-11.077-45.837-11.077C45.193,255.546,0,300.739,0,356.286s45.193,100.74,100.74,100.74
		s100.74-45.193,100.74-100.74c0-28.859-12.217-54.904-31.728-73.287l8.139-11.547c22.241,20.873,35.448,50.887,35.448,82.481
		v22.672c0,7.836,6.352,14.189,14.189,14.189h56.849c7.825,0,14.172-6.334,14.189-14.159l0.048-23.016
		C298.693,316.067,316.829,281.429,347.132,260.965z M173.103,356.288c0,39.902-32.461,72.363-72.363,72.363
		s-72.363-32.461-72.363-72.363s32.461-72.363,72.363-72.363c10.415,0,20.313,2.229,29.269,6.206l-40.867,57.981
		c-4.515,6.406-2.982,15.257,3.424,19.772c2.484,1.751,5.336,2.592,8.161,2.592c4.458,0,8.847-2.096,11.611-6.016l40.848-57.954
		C165.514,319.489,173.103,337.014,173.103,356.288z"
      />
    </g>
  </svg>
)
