import React from "react"

export const SeeMoreIcon = () => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.3321 0.944183C2.85003 0.944183 0.0134888 3.78072 0.0134888 7.26279C0.0134888 10.7449 2.85003 13.5814 6.3321 13.5814C9.81417 13.5814 12.6507 10.7449 12.6507 7.26279C12.6507 3.78072 9.81417 0.944183 6.3321 0.944183ZM6.3321 1.91628C9.29015 1.91628 11.6786 4.30474 11.6786 7.26279C11.6786 10.2208 9.29015 12.6093 6.3321 12.6093C3.37405 12.6093 0.985582 10.2208 0.985582 7.26279C0.985582 4.30474 3.37405 1.91628 6.3321 1.91628ZM5.84605 4.83256V6.77675H3.90186V7.74884H5.84605V9.69303H6.81814V7.74884H8.76233V6.77675H6.81814V4.83256H5.84605Z"
      fill="currentColor"
    />
  </svg>
)
