import React from "react"

export const LifeSavingInsurance = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={59} fill="none">
    <path
      fill="#fff"
      stroke="#09C"
      strokeWidth={0.327}
      d="M3.172 8.329 24.553.489a3.271 3.271 0 0 1 2.322.026l19.818 7.786a3.246 3.246 0 0 1 2.059 2.818c.372 6.083.324 9.934-.13 16.746-.79 11.854-7.77 23.378-21.452 30.363a3.084 3.084 0 0 1-2.878-.046C11.003 50.935 3.59 41.293 2.01 27.865c-.591-6.611-1.212-10.608-.945-16.697.056-1.285.9-2.396 2.108-2.84Z"
    />
    <path
      fill="#09C"
      d="M40.599 11.323 25.624 5.948 10.8 11.256c-1.893.885-3.539 1.43-3.539 3.19v8.777a33.178 33.178 0 0 0 4.866 17.473 30.428 30.428 0 0 0 13.643 12.05c6.06-1.968 10.586-7.219 13.351-11.705a33.178 33.178 0 0 0 4.888-17.473V15.67c0-2.517-1.336-3.742-3.41-4.348Z"
    />
    <path
      fill="#333"
      d="M25.753 47.645a24.202 24.202 0 0 1-10.794-9.609 26.291 26.291 0 0 1-3.862-13.829V18.05c0-1.394.84-2.703 2.513-3.482l12.002-4.486v-4.14L10.787 11.25c-1.558.677-3.54 1.43-3.54 3.19v8.777a33.178 33.178 0 0 0 4.867 17.473 30.426 30.426 0 0 0 13.643 12.05l-.005-5.095Z"
      opacity={0.3}
    />
    <path
      fill="#333"
      d="M25.761 52.74c1.016-.332 2-.754 2.942-1.26a26.356 26.356 0 0 0 6.224-4.897 33.39 33.39 0 0 0 4.331-5.817A31.948 31.948 0 0 0 44 23.124v-7.25c0-3.61-1.862-3.876-3.42-4.557L25.616 5.942v4.14l12.148 4.553c1.672.783 2.513 2.088 2.513 3.486v6.157a26.25 26.25 0 0 1-3.867 13.825 23.587 23.587 0 0 1-10.648 9.537v5.1Z"
      opacity={0.4}
    />
    <path
      fill="#fff"
      d="M13.532 23.732a4.754 4.754 0 0 1-.22-1.217 20.245 20.245 0 0 1-.125-2.982c0-.583.058-1.136.111-1.64.02-.46.133-.912.332-1.328.144-.283.373-.513.654-.659.204-.097.332-.093.332-.075a1.68 1.68 0 0 0-.818.818 3.56 3.56 0 0 0-.261 1.261c-.04.5-.07 1.048-.089 1.628a35.42 35.42 0 0 0 .045 2.955c.048.411.062.825.04 1.239ZM13.758 27.552c-.07 0-.137-.359-.146-.805-.008-.447.045-.81.115-.814.071-.005.137.362.146.81.01.446-.053.809-.115.809Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M17.753 29.527a.375.375 0 0 1-.341-.225c-.014-.031-1.344-3.126 1.712-5.484a.37.37 0 0 1 .525.076.39.39 0 0 1-.074.537c-2.58 1.992-1.492 4.528-1.481 4.553a.387.387 0 0 1-.186.508.365.365 0 0 1-.155.035Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m33.835 23-.108-.461-1.97-1.518-.714.107c2.861 1.546 2.094 4.57 1.795 5.377-1.755 4.267-6.447 8.592-6.704 8.846-.006.006-.014.009-.02.014a.184.184 0 0 1-.109.04.183.183 0 0 1-.075-.016h-.002s-7.01-2.351-9.118-5.67a2.609 2.609 0 0 1-.278-.545l-.042-.045c.003.032.007.063.008.096.017.515.332 1.03.672 1.479.12.111.23.235.327.372l.095.133c.322.124.608.354.82.7.367.107.702.364.944.806.046.058.093.115.138.175.193.092.384.222.569.402 1.048 1.022 2.543 1.713 3.814 2.411 1.109.61 2.32 1.436 3.566 1.77.01-.015.019-.032.03-.047a7.844 7.844 0 0 1 1.538-1.643 2.1 2.1 0 0 1 .323-.565c1.612-2.031 3.322-4.124 4.508-6.457 1.078-2.126 1.16-3.957-.007-5.76Z"
      clipRule="evenodd"
    />
    <path
      fill="#333"
      fillRule="evenodd"
      d="M34.394 21.648a.173.173 0 0 1-.144-.052c-.971-.996-2.097-1.53-3.348-1.588a.174.174 0 0 1-.165-.182.174.174 0 0 1 .182-.165c1.34.063 2.544.632 3.58 1.693a.174.174 0 0 1-.105.294Z"
      clipRule="evenodd"
    />
    <mask
      id="a"
      width={4}
      height={3}
      x={31}
      y={18}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance"
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M31.19 18.579H35v1.987h-3.81V18.58Z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#333"
        fillRule="evenodd"
        d="M34.846 20.565a.173.173 0 0 1-.144-.05c-.971-.997-2.098-1.532-3.348-1.59a.173.173 0 1 1 .017-.346c1.34.062 2.544.632 3.58 1.693a.174.174 0 0 1-.105.293Z"
        clipRule="evenodd"
      />
    </g>
    <mask
      id="b"
      width={13}
      height={17}
      x={15}
      y={21}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance"
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.887 22H27.48V37.6H15.887V22Z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#b)">
      <path
        fill="#333"
        fillRule="evenodd"
        d="M27.29 37.601a.19.19 0 0 1-.077-.016c-.369-.163-9.047-4.028-10.878-8.085-.732-1.623-.559-3.506.463-5.035a5.49 5.49 0 0 1 4.771-2.462c.292.009.588.046.88.108 1.437.31 2.711 1.227 3.41 2.454a.19.19 0 0 1-.331.188c-.645-1.135-1.826-1.984-3.16-2.27a4.553 4.553 0 0 0-.81-.101 5.131 5.131 0 0 0-4.444 2.294c-.949 1.42-1.11 3.165-.433 4.668 1.771 3.925 10.596 7.855 10.685 7.894a.19.19 0 0 1-.077.363Z"
        clipRule="evenodd"
      />
      <path
        stroke="#444"
        strokeWidth={0.031}
        d="M27.29 37.601a.19.19 0 0 1-.077-.016c-.369-.163-9.047-4.028-10.878-8.085-.732-1.623-.559-3.506.463-5.035a5.49 5.49 0 0 1 4.771-2.462c.292.009.588.046.88.108 1.437.31 2.711 1.227 3.41 2.454a.19.19 0 0 1-.331.188c-.645-1.135-1.826-1.984-3.16-2.27a4.553 4.553 0 0 0-.81-.101 5.131 5.131 0 0 0-4.444 2.294c-.949 1.42-1.11 3.165-.433 4.668 1.771 3.925 10.596 7.855 10.685 7.894a.19.19 0 0 1-.077.363"
      />
    </g>
    <path
      fill="#333"
      fillRule="evenodd"
      d="M27.29 37.601a.19.19 0 0 1-.132-.326c.08-.076 7.899-7.713 7.407-12.066-.261-2.312-2.183-4.05-4.375-3.968-2.134.084-3.803 1.353-4.252 3.232l-.071.2a.19.19 0 1 1-.357-.129l.066-.18c.482-2.028 2.29-3.411 4.599-3.502 2.38-.087 4.484 1.796 4.767 4.304.513 4.538-7.191 12.064-7.52 12.382a.19.19 0 0 1-.131.053Z"
      clipRule="evenodd"
    />
    <path
      stroke="#444"
      strokeWidth={0.031}
      d="M27.29 37.601a.19.19 0 0 1-.132-.326c.08-.076 7.899-7.713 7.407-12.066-.261-2.312-2.183-4.05-4.375-3.968-2.134.084-3.803 1.353-4.252 3.232l-.071.2a.19.19 0 1 1-.357-.129l.066-.18c.482-2.028 2.29-3.411 4.599-3.502 2.38-.087 4.484 1.796 4.767 4.304.513 4.538-7.191 12.064-7.52 12.382a.19.19 0 0 1-.131.053"
    />
  </svg>
)
